import React, {useEffect} from 'react'
import styled from '@emotion/styled'
import {Link} from 'gatsby'
import {motion, useAnimation} from 'framer-motion'
// import { useInView } from 'react-intersection-observer';

// import {MEDIA} from '../../utils/constants';

import {IoIosArrowDropupCircle} from 'react-icons/io'
import {IoMdInformationCircle} from 'react-icons/io'
import {MdMail} from 'react-icons/md'

const FloatingMenu = () => {
  // const controls = useAnimation();

  // const { ref, inView } = useInView({
  //     rootMargin: '-100px 0px',
  //   }
  // )

  // useEffect(() => {
  //   if (inView) controls.start('hidden')
  // }, [controls, inView]);

  const scrollTop = () => {
    window.scrollTo({top: 0, behavior: 'smooth'})
  }

  return (
    <StyledDiv
      // ref={ref}
      // initial='hidden'
      // animate={controls}
      // variants={{
      //   visible: { opacity: 1},
      //   hidden: { opacity: 0}
      // }}
      // transition={{ duration: 1 }}
      initial={{opacity: 0}}
      animate={{opacity: 0.3}}
      whileHover={{opacity: 1}}
      transition={{duration: 1}}
    >
      <IconDiv
        whileHover={{scale: 1.1}}
        whileTap={{scale: 0.9}}
        onClick={scrollTop}
      >
        <IoIosArrowDropupCircle />
      </IconDiv>
      <Link to='/about'>
        <IconDiv whileHover={{scale: 1.1}} whileTap={{scale: 0.9}}>
          <IoMdInformationCircle />
        </IconDiv>
      </Link>
      <Link to='/contact'>
        <IconDiv whileHover={{scale: 1.1}} whileTap={{scale: 0.9}}>
          <MdMail />
        </IconDiv>
      </Link>
    </StyledDiv>
  )
}

export default FloatingMenu

const StyledDiv = styled(motion.div)`
  position: fixed;
  top: 40%;
  left: 0.5rem;

  display: flex;
  display: none;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  border-radius: 2rem;
  padding: 0.5rem;
  /* border: 2px solid gray; */
  background-color: white;
  box-shadow: 3px 3px 15px 2px hsl(0, 0%, 80%);
`

const IconDiv = styled(motion.div)`
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 1rem 0;
  color: gray;
  font-size: 1.5rem;
  &:hover {
    cursor: pointer;
  }
`
