import React from 'react'
import {StaticQuery, graphql} from 'gatsby'
import styled from '@emotion/styled'
import {motion} from 'framer-motion'

import ReactMarkdown from 'react-markdown'

import Layout from '../components/mainLayout'
import ContentSection from '../components/ContentSection'
import FloatingMenu from '../components/FloatingMenu'
import {MEDIA} from '../utils/constants'

import street from '../assets/street/street3.jpg'

import FB from '../assets/socialMedia/FB.png'
import IG from '../assets/socialMedia/IG.png'
import T from '../assets/socialMedia/Twitter.png'
import PageTitle from '../components/PageTitle/PageTitle'

const IndexPage = ({data}) => {
  const WordCloudComponent = React.lazy(() =>
    import('../components/WordCloudComponent/WordCloudComponent'),
  )
  const isSSR = typeof window === 'undefined'

  const defaultTitle = 'Do You Know What Justice Looks Like?'
  let title = data?.strapiWebpage?.page_in_lang[0]?.title
    ? data?.strapiWebpage?.page_in_lang[0]?.title
    : defaultTitle

  return (
    <Layout>
      <Wrapper id='#top'>
        <FloatingMenu />
        <PageTitle text={title} />
        <Content>
          <ContentSection>
            <ReactMarkdown
              escapeHtml={false}
              source={data?.strapiWebpage?.page_in_lang[0]?.content}
            />
            <CenterDiv>
              <ReactMarkdown
                escapeHtml={false}
                source={data?.strapiWebpage?.page_in_lang[0]?.centered_content}
              />
            </CenterDiv>
          </ContentSection>
        </Content>
        <Cloud>
          <CloudTitle>What does justice look like?</CloudTitle>
          {!isSSR && (
            <React.Suspense fallback={<div />}>
              <WordCloudComponent
                contentArray={data?.allStrapiCloudEntry?.edges}
              />
            </React.Suspense>
          )}
        </Cloud>
        <Social>
          <SocialLink whileHover={{scale: 1.1}} whileTap={{scale: 0.9}}>
            <a href='https://instagram.com/thejusticelibrary/' target='_blank'>
              <img src={IG} alt='instagram logo link' />
            </a>
          </SocialLink>
          <SocialLink whileHover={{scale: 1.1}} whileTap={{scale: 0.9}}>
            <a href='https://twitter.com/tjl36683465' target='_blank'>
              <img
                style={{filter: 'grayscale(100%)'}}
                src={T}
                alt='twitter logo link'
              />
            </a>
          </SocialLink>
        </Social>
      </Wrapper>
    </Layout>
  )
}

export const query = graphql`
  query WebpageHomeQuery {
    strapiWebpage(name: {eq: "home"}) {
      strapiId
      page_in_lang {
        title
        lang
        content
        centered_content
      }
    }
    allStrapiCloudEntry {
      edges {
        node {
          content
        }
      }
    }
  }
`

export default IndexPage

const Wrapper = styled(motion.div)`
  /* height: 100%; */
  /* padding: 0 1rem; */
  @media (min-width: ${MEDIA.tablet}) {
    /* padding: 0 2rem; */
  }
  @media (min-width: ${MEDIA.desktop}) {
  }
`

const Content = styled.div`
  margin: 0 0;
  font-size: 1.5rem;
  p:first-of-type {
    font-size: 1.75rem;
  }

  @media (min-width: ${MEDIA.tablet}) {
    margin: 0 1rem;
  }
  @media (min-width: ${MEDIA.desktop}) {
    margin: 0 2rem;
  }
`

const CenterDiv = styled.div`
  p {
    text-align: center;
  }
`
const Cloud = styled.section``

const CloudTitle = styled.h3`
  font-size: 2rem;
  width: fit-content;
  margin: auto;
  align-content: center;
  text-align: center;
`

const StyledPara1 = styled.p`
  text-align: center;
  font-size: 1.5rem;
  padding: 0.5rem 0 2rem;
  span {
    display: inline-block;
  }
`
const StyledPara2 = styled(StyledPara1)`
  padding: 0rem 0 2rem;
  margin-top: -1.5rem;
`

const Line = styled.div`
  height: 2px;
  background-color: var(--nav-color);
  margin: 4rem 0;
`
const Social = styled.div`
  display: flex;
  justify-content: center;
  margin: 5rem 1rem 0 1rem;
  padding: 0 3px;
  text-align: center;
  border-top: 2px solid var(--nav-color);
  /* border-bottom: 2px solid var(--nav-color); */
  img {
    width: 20px;
    height: 20px;
  }
`

const SocialLink = styled(motion.div)`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
  padding: 1rem 1rem;
  margin: 0 1rem;
  &:hover {
    cursor: pointer;
  }

  @media (min-width: ${MEDIA.tablet}) {
    padding: 1rem 2rem;
    margin: 0 1rem;
  }
  @media (min-width: ${MEDIA.desktop}) {
    padding: 1rem 2rem;
    margin: 0 3rem;
  }
`
