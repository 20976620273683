import React from 'react'
import styled from '@emotion/styled'
import {motion} from 'framer-motion'
import street from '../../assets/street/street3.jpg'
import library from '../../assets/library/library2.jpg'
// import library from '../../assets/library/librarySepia.png';
// import library from '../../assets/library/libraryspook.png';
import {MEDIA} from '../../utils/constants'

const PageTitle = ({text}) => {
  return (
    <TitleContainer>
      <BackgroundDiv alt='cover image' />
      <Title
        initial={{opacity: 0, y: -60}}
        animate={{opacity: 1, y: 0}}
        transition={{duration: 1.3}}
      >
        {text}
      </Title>
    </TitleContainer>
  )
}

export default PageTitle

const TitleContainer = styled(motion.div)`
  position: relative;
  min-height: calc(100vh - var(--nav-height) - 3rem);
  max-height: calc(100vh - var(--nav-height) - 3rem);
  width: 100%;
  /* padding-bottom: var(--nav-height); */
  display: flex;
  align-items: center;
  margin: 0;
  padding: 0 2rem 0 1rem;

  /* background-image: url(${street}); */
  /* background-image: url(${library});
  background-blend-mode:luminosity;
  background-position: top; 
  background-repeat: no-repeat; 
  background-size: 100% 95%;  */

  /* box-shadow: 0 4px 2px -2px gray; */
  /* -moz-box-shadow:    inset 0 4px 2px -2px gray;
  -webkit-box-shadow: inset 0 4px 2px -2px gray;
  box-shadow:         inset 0 4px 2px -2px gray; */
  @media (min-width: ${MEDIA.tablet}) {
    padding: 0;
  }
`

const BackgroundDiv = styled.div`
  position: absolute;
  background: transparent;
  filter: blur(2px);
  height: 100%;
  width: 100%;
  top: 0;
  left: 0;
  /* background: var(--nav-color); */
  background-image: url(${street});
  /* background-image: url(${library}); */
  background-position: top;
  background-repeat: no-repeat;
  background-size: 100% 95%;
`

const Title = styled(motion.h1)`
  max-width: 100%;
  padding: 4rem 0;
  font-size: max(50px, min(7vw, 90px));
  font-size: var(--main-title);
  text-align: center;
  text-transform: uppercase;
  color: var(--background-color);
  /* color: black; */
  /* text-shadow: 2px 2px hsl(207 5% 73% / 1); */
  /* text-shadow: 
    2px 1px 3px white,
    3px 3px 5px gray; */
  text-shadow: 4px 4px 2px hsl(207 5% 13% / 1);
  text-align: left;
  z-index: 2;
  @media (min-width: ${MEDIA.tablet}) {
    max-width: 85%;
    line-height: 10rem;
    padding-left: 2.5rem;
  }
  @media (min-width: ${MEDIA.desktop}) {
    max-width: 75%;
    padding-left: 5rem;
  } ;
`
